<template>
  <div class="flex w-fit">
    <div v-for="(button, idx) in data" :key="idx">
      <Button
        :label="button.title && button.title"
        :to="
          button.pageLink !== null
            ? button.hash
              ? `${localePathByType(
                  button.pageLink?.__typename as string,
                  button.pageLink?.slug as string
                )}${button.hash}`
              : localePathByType(
                  button.pageLink?.__typename as string,
                  button.pageLink?.slug as string
                )
            : button.externalLink
        "
        :target="button.externalLink ? '_blank' : undefined"
        :variant="button.primary ? 'primary' : 'secondary'"
        :class="{ 'ml-4': idx === 1 }"
        :theme="theme"
        :size="size"
        :filled="filled"
        :isTransparentBgNeutral="isTransparentBgNeutral"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CallToActionFragment } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Array as PropType<CallToActionFragment[]>
  },
  theme: {
    type: String,
    default: 'light'
  },
  size: {
    type: String,
    default: 'default'
  },
  filled: {
    type: Boolean,
    default: false
  },
  isTransparentBgNeutral: {
    type: Boolean,
    default: false
  }
})
</script>
